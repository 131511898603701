<template>
  <v-row justify="center">
    <v-dialog
      v-model="importDialog"
      max-width="730px"
      :change="closeModal()"
    >
      <v-card>
        <v-card-title class="dialog-title">
          <span>{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text class="dialog-subtitle">
          {{ dialogSubtitle }}
        </v-card-text>
        <v-card-text class="dialog-form">
          <import-form
            @submited="submitData"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ImportForm from '@/components/forms/register/ImportForm.vue';

export default {
  name: 'ImportDrivers',
  components: {
    ImportForm,
  },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      importDialog: false,
    };
  },
  computed: {
    dialogTitle() {
      return this.$t('components.lists.profiles.import.title');
    },

    dialogSubtitle() {
      return this.$t('components.lists.profiles.import.sub_title');
    },
  },
  watch: {
    dialog(value) {
      this.importDialog = value;
    },
  },
  methods: {
    closeModal() {
      this.$emit('change', this.importDialog);
    },
    submitData(payload) {
      this.$emit('importDrivers', payload);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
