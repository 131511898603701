<template>
  <v-container class="pb-0">
    <v-row>
      <v-col>
        <div class="d-flex align-center mb-10">
          <label
            :class="['file-input-button', select]"
            for="csv"
          >
            <span>{{ labelText }}</span>

          </label>
          <v-btn
            v-if="select"
            class="ml-2"
            elevation="0"
            icon
            large
            @click="cleanFile"
          >
            <v-icon color="list_placeholder">
              mdi-delete-outline
            </v-icon>
          </v-btn>
          <v-file-input
            id="csv"
            v-model="csv"
            type="file"
            accept=".csv"
            prepend-icon=""
            hidden
          />
        </div>
        <div class="d-flex justify-space-between">
          <v-btn
            class="button import"
            color="accent"
            :disabled="!hasFile"
            @click="submitCsv"
          >
            {{ buttonText }}
          </v-btn>

          <v-btn
            class="button dowload"
            color="accent"
            outlined
            :href="template"
            download
          >
            {{ dowloadButton }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'ImportForm',
  data() {
    return {
      csv: [],
      select: '',
      template: '/template.xlsx',
    };
  },
  computed: {
    buttonText() {
      return this.$t('components.lists.buttons.complete_import');
    },
    labelText() {
      if (this.select) return this.csv.name;

      return this.$t('components.lists.profiles.import.label');
    },
    dowloadButton() {
      return this.$t('components.lists.profiles.import.download');
    },
    hasFile() {
      return this.select;
    },
  },
  watch: {
    csv(value) {
      if (value) {
        this.select = 'csv-select';
      } else {
        this.select = '';
      }
    },
  },
  methods: {
    submitCsv() {
      const formData = new FormData();
      formData.append('users_csv', this.csv);
      this.$emit('submited', formData);
    },

    cleanFile() {
      this.csv = null;
    },
  },
};
</script>

<style lang="scss" scoped>

  .file-input-button {
    cursor: pointer;
    background-color: $color_background;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    border: 2px;
    border-radius: 4px;
    border-style: dashed;
    margin-top: 0;
  }

  .v-input.theme--light.v-text-field.v-text-field--is-booted.v-file-input {
    display: none;
  }

  .csv-select {
    background-color: $color_background;
    color: $color_primary;
    border: 2px $color_primary;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    border-radius: 4px;
    border-style: dashed;
    margin-top: 0;
    cursor: pointer;
  }

  .import {
    width: 230px;
    margin-right: 10px;
  }

  .dowload {
    width: 230px;
    margin-left: 10px;
  }
</style>
