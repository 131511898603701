import { render, staticRenderFns } from "./DriverList.vue?vue&type=template&id=d6e74052&scoped=true&"
import script from "./DriverList.vue?vue&type=script&lang=js&"
export * from "./DriverList.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/lists/drivers.scss?vue&type=style&index=0&id=d6e74052&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6e74052",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VSimpleTable,VTooltip})
