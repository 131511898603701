<template>
  <div class="users">
    <driver-header
      @fetchDrivers="fetchDrivers"
      @openModal="openModal(false, user, false)"
      @dialogImportDrivers="dialogImportDrivers"
    />
    <driver-list
      :users="getUsers.data"
      :active="active"
      @editDriver="editUser"
      @deactivateDriver="deactivateDriverSetup"
    />
    <driver-dialog
      v-model="dialogDriver"
      :edit="edit"
      :user="user"
      :reset-form="resetForm"
      :deactivate="shouldDeactivate"
      :profiles="getDriversProfiles"
      :business-units="getBusinessUnits"
      :branches="getBranches"
      @createUser="createUser"
      @updateUser="updateUser"
      @deactivateDriver="deactivateDriver"
      @fetchBranchesByBusinessUnit="fetchBranches"
    />
    <import-dialog
      v-model="dialogImport"
      @importDrivers="importUsers"
    />
    <v-row
      justify="end"
    >
      <pagination
        v-if="showPagination"
        :pagination-settings="getUsers.pagination_settings"
        :page="getPage"
        @changed="changePage"
      />
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DriverHeader from '@/components/headers/DriverHeader.vue';
import DriverList from '@/components/lists/DriverList.vue';
import DriverDialog from '@/components/dialogs/DriverDialog.vue';
import ImportDialog from '@/components/dialogs/ImportDialog.vue';
import Pagination from '@/components/pagination/Pagination.vue';

export default {
  name: 'Users',
  components: {
    DriverHeader,
    DriverList,
    DriverDialog,
    ImportDialog,
    Pagination,
  },
  data() {
    return {
      edit: false,
      shouldDeactivate: false,
      active: true,
      dialogDriver: false,
      dialogImport: false,
      resetForm: false,
      user: {
        id: '',
        name: '',
        email: '',
        cpf: '',
        phone: '',
        kind: '',
        admission_date: '',
      },
      params: {
        by_active: true,
        page: 1,
      },
    };
  },
  computed: {
    showPagination() {
      if (this.getUsers.data && this.getUsers.data.length !== 0) {
        return true;
      }
      return false;
    },
    ...mapGetters([
      'getUsers',
      'getDriversProfiles',
      'getRegisterSuccess',
      'getCurrentBreadcrumbs',
      'getBusinessUnits',
      'getPage',
      'getBranches',
    ]),
  },
  watch: {
    getRegisterSuccess(value) {
      if (value) {
        this.closeModal();
        this.fetchUsers({ by_active: true });
        this.resetForm = true;
      }
    },
  },
  mounted() {
    this.initLoading();
    this.fetchDriverProfiles(true);
    this.fetchBusinessUnits({ by_active: true });
    this.fetchBranches({ by_active: true });
    this.fetchUsers({
      by_active: true,
      page: this.getPage,
    });
    this.initCurrentBreadcrumbs([
      {
        text: this.$i18n.t('breadcrumbs.register_driver'),
        disabled: true,
      },
    ]);
  },
  methods: {
    ...mapActions([
      'fetchUsers',
      'registerUser',
      'updateDriver',
      'deactivateUser',
      'fetchDriverProfiles',
      'initCurrentBreadcrumbs',
      'fetchBusinessUnits',
      'fetchBranches',
      'importDrivers',
      'initLoading',
      'updatePage',
    ]),
    fetchDrivers(term, active) {
      this.updatePage(1);
      this.active = active;
      this.initLoading();
      this.fetchUsers({ by_term: term, by_active: active });
    },
    openModal(isEdit, user, isDeactivate) {
      this.edit = isEdit;
      this.shouldDeactivate = isDeactivate;

      if (!isDeactivate) {
        this.setUser(isEdit, user);
      }

      this.dialogDriver = true;
    },
    closeModal() {
      this.dialogDriver = false;
      this.dialogImport = false;
    },
    dialogImportDrivers() {
      this.dialogImport = true;
    },
    createUser(payload) {
      this.registerUser(payload);
    },
    importUsers(payload) {
      this.importDrivers(payload);
    },
    updateUser(payload) {
      this.updateDriver(payload);
    },
    editUser(user) {
      this.openModal(true, user, false);
    },
    deactivateDriverSetup(user) {
      this.user = user;
      this.openModal(true, user, true);
    },
    setUser(isEdit, user) {
      if (isEdit) {
        this.user = user;
      } else {
        this.user = {
          id: '',
          name: '',
          email: '',
          cpf: '',
          phone: '',
          state_id: '',
          kind: '',
          category: {
            id: '',
            name: '',
          },
          admission_date: '',
        };
      }
    },
    deactivateDriver(managerPassword, userId) {
      this.deactivateUser({ id: userId, manager_password: managerPassword });
    },
    changePage() {
      this.params.page = this.getPage;
      this.fetchUsers(this.params);
    },
  },
};
</script>
