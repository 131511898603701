<template>
  <div>
    <v-simple-table
      v-if="usersPresent"
      class="jornada-list"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('components.lists.drivers.name') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.drivers.cpf') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.drivers.kind.header') }}
            </th>
            <th class="text-center">
              {{ $t('components.lists.drivers.unit') }}
            </th>
            <th class="text-center" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in users"
            :key="user.id"
            class="list-row"
          >
            <td
              @click="goToSettings(user.id)"
            >
              {{ user.name }}
            </td>
            <td
              class="text-center"
              @click="goToSettings(user.id)"
            >
              {{ user.cpf }}
            </td>
            <td
              class="text-center"
              @click="goToSettings(user.id)"
            >
              {{ user.driver_profile_name }}
            </td>
            <td
              class="text-center"
              @click="goToSettings(user.id)"
            >
              {{ user.business_unit_name }}
            </td>
            <td class="text-center buttons">
              <v-tooltip
                v-if="active"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="icon"
                    color="accent"
                    v-bind="attrs"
                    v-on="on"
                    @click="deactivateDriver(user)"
                  >
                    mdi-minus-circle
                  </v-icon>
                </template>
                <span>{{ $t('components.lists.drivers.tooltips.deactivate') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="active"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="icon"
                    color="accent"
                    v-bind="attrs"
                    v-on="on"
                    @click="editDriver(user)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('components.lists.drivers.tooltips.edit') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <placeholder
      v-else
      :message="$t('components.lists.users.empty')"
    />
  </div>
</template>

<script>
import Placeholder from '@/components/lists/Placeholder.vue';

export default {
  name: 'DriverList',
  components: {
    Placeholder,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    usersPresent() {
      return this.users.length !== 0;
    },
  },
  methods: {
    editDriver(user) {
      this.$emit('editDriver', user);
    },

    deactivateDriver(user) {
      this.$emit('deactivateDriver', user);
    },
    goToSettings(userId) {
      this.$router.push({ name: 'userSettings', params: { userId } });
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/lists/drivers.scss" scoped />
