<template>
  <v-container class="header">
    <v-row
      no-gutters
      class="content"
    >
      <div>
        <v-form
          autocomplete="off"
          @submit.prevent
        >
          <v-text-field
            v-model="term"
            v-debounce="fetchDrivers"
            outlined
            dense
            type="text"
            :label="$t('components.header.users.driver')"
            loader-height="1"
            color="accent"
            height="42"
            class="search"
          >
            <v-icon
              slot="prepend-inner"
              left
              color="accent"
            >
              mdi-magnify
            </v-icon>
          </v-text-field>
        </v-form>
      </div>
      <v-spacer />
      <div>
        <v-btn
          outlined
          color="accent"
          class="button pa-5"
          @click="toggleActiveList"
        >
          {{ $t(`components.lists.buttons.active.${active}`) }}
        </v-btn>

        <v-btn
          outlined
          color="accent"
          class="ml-6 button pa-5"
          @click="openDialogImport"
        >
          {{ $t('components.lists.buttons.import_driver') }}
        </v-btn>

        <v-btn
          color="accent"
          class="ml-6 button pa-5"
          @click="openDialogDriver"
        >
          {{ $t('components.lists.buttons.register') }}
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DriverHeader',
  data() {
    return {
      active: true,
      term: '',
    };
  },
  methods: {
    toggleActiveList() {
      this.active = !this.active;
      this.fetchDrivers();
    },
    fetchDrivers() {
      this.$emit('fetchDrivers', this.term, this.active);
    },
    openDialogDriver() {
      this.$emit('openModal');
    },
    openDialogImport() {
      this.$emit('dialogImportDrivers');
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/headers/drivers.scss" scoped />
